body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.review-carousel .slick-list .slick-slide >div>div {
  display: flex !important
}

.review-carousel .slick-dots {
  bottom: -40px;
}

.review-carousel .slick-dots li.slick-active button{
  background:#59703A !important
}

.review-carousel .slick-dots li button {
  background: #59703A !important
}

.form-input{
  height: 56px !important;
  width: 100% !important
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
